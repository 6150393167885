<script setup lang="ts">
const { staticUrl } = useRuntimeConfig().public;
import type { LinkWithTitleAndPreview } from '@glow/shared/utils/types';
import { mobileAppLinks } from '@glow/shared/lib/constants';

const storeLinks: LinkWithTitleAndPreview[] = [
  {
    title: 'Google play',
    link: mobileAppLinks.googlePlay,
    imgSrc: `${staticUrl}/images/app/googleplay.png`,
  },
  {
    title: 'App store',
    link: mobileAppLinks.appStore,
    imgSrc: `${staticUrl}/images/app/appstore.png`,
  },
  {
    title: 'App gallery',
    link: mobileAppLinks.appGallery,
    imgSrc: `${staticUrl}/images/app/appgallery.png`,
  },
  {
    title: 'RuStore',
    link: mobileAppLinks.ruStore,
    imgSrc: `${staticUrl}/images/app/rustore.png`,
  },
];
</script>

<template>
  <div class="app-block">
    <NuxtImg :src="`${staticUrl}/images/app/qr.svg`" width="166" />
    <div>Скачивайте наше мобильное приложение</div>
    <div class="app-block__stores">
      <NuxtLink
        v-for="[index, store] in storeLinks.entries()"
        :key="index"
        :to="store.link"
        target="_blank"
      >
        <NuxtImg width="32" :src="store.imgSrc" />
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-block {
  border-radius: 32px;
  border: 4px solid var(--app-color-primary);
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;

  &__stores {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
  }
}
</style>
